import React, { createContext, useState, useRef } from "react";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitUrl, setExitUrl] = useState("");
  const [exitNoreferrer, setExitNoreferrer] = useState(false);
  const footerRef = useRef(null);

  const openExitModal = (url, exitNoreferrer) => {
    setExitUrl(url);
    setExitNoreferrer(exitNoreferrer);
    setShowExitModal(true);
  };

  const closeExitModal = () => {
    setShowExitModal(false);

    // bug fix: prevent the modal from closing before the exit URL is opened
    // without this, the exit url clears too fast, and the browser opens a tab with the same url instead of the exit one
    setTimeout(() => {
      setExitUrl("");
      setExitNoreferrer(false);
    }, 100);
  };

  const store = {
    showExitModal,
    exitUrl,
    exitNoreferrer,
    openExitModal,
    closeExitModal,
    footerRef,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
