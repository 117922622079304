import React from "react";
import { AppProvider } from "@context/AppContext";

// Fonts
import "@fontsource/barlow-condensed/400.css";
import "@fontsource/barlow-condensed/600.css";
import "@fontsource/barlow-condensed/700.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

// Global styles
import "./src/styles/global.css";
import "./src/styles/cookies.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
